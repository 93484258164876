import Paper, { PaperProps } from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { vwMobile } from "theme/muiSizes";

export const CustomPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: "10px",
  width: "100%",
  minHeight: "120px",
  height: "auto",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  boxShadow: "none",
  boxSizing: "border-box",
  padding: "10px 15px",
  [theme.breakpoints.down(440)]: {
    borderRadius: vwMobile(10),
    minHeight: vwMobile(120),
    padding: `${vwMobile(10)} ${vwMobile(15)}`,
  },
}));

export const ProductName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Montserrat-SemiBold",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "65px",
  textAlign: "center",
  [theme.breakpoints.down(440)]: {
    minHeight: vwMobile(65),
  },
}));

export const Price = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "0.875rem",
  fontFamily: "Montserrat-Medium",
  flexGrow: 1,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  boxSizing: "border-box",
  paddingTop: "5%",
}));
