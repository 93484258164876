import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ImageFailedToLoad = styled("img")(() => ({
  width: "30%",
  objectFit: "scale-down",
}));

export const FailedToLoadMessage = styled(Box)<BoxProps>(() => ({
  fonSize: "1rem",
  fontFamily: "Montserrat-Medium",
  marginBottom: "1.25rem",
}));
